import React, { useState, useEffect } from 'react';

const Navbar = () => {
  const [logoSrc, setLogoSrc] = useState("logo_horizontal_azul_amarillo.webp");

  const handleScroll = () => {
    const isDesktop = window.innerWidth >= 992;
    const isScrolled = window.scrollY > 100;

    if (isDesktop && isScrolled) {
      setLogoSrc("logo_horizontal_azul_amarillo.webp");
    } else if (isDesktop && !isScrolled) {
      setLogoSrc("logo_horizontal_blanco.webp");
    } else {
      setLogoSrc("logo_horizontal_azul_amarillo.webp");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
      <div className="container">
        <a className="navbar-brand js-scroll-trigger" href="#page-top">
          <img
            src={logoSrc}
            alt="Royal Bus Guatemala"
            width="150"
            height="auto"
            id="brand-logo"
          />
        </a>
        <button
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ml-auto my-2 my-lg-0">
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#about">
                Acerca de
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#services">
                Beneficios
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#portfolio">
                Portafolio
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="#contact">
                Contacto
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
