import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
      <section className="page-section bg-primary" id="about">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="text-white mt-0">Tu solución de transporte confiable y personalizada</h2>
              <hr className="divider light my-4" />
              <p className="text-white mb-4">
                En <strong>Royal Bus</strong>, somos más que solo transporte. Con más de 20 años de experiencia, ofrecemos servicios de transporte de alta calidad, tanto para empresas como para particulares, garantizando seguridad, puntualidad y confort en cada viaje. 
                Gracias a nuestra flota propia, podemos ofrecer los mejores precios sin intermediarios.
              </p>
              <p className="text-white mb-4">
                Desde traslados corporativos hasta tours turísticos, nos adaptamos a tus necesidades. Nuestro compromiso es brindarte un servicio premium con un toque personal.
              </p>
              <a className="btn btn-light btn-xl js-scroll-trigger" href="#contact">Solicita una cotización</a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
