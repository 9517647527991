import React, { Component } from 'react';

class Masthead extends Component {
    render() {
        return (
            <div id="page-top">
                <header className="masthead">
                    <div className="container h-100">
                        <div className="row h-100 align-items-center justify-content-center text-center">
                            <div className="col-lg-10 align-self-end">
                                <h1 className="text-uppercase text-white font-weight-bold">
                                    Líderes en transporte turístico y corporativo
                                </h1>
                                <hr className="divider my-4" />
                            </div>
                            <div className="col-lg-8 align-self-baseline">
                                <p className="text-white-75 font-weight-light mb-5">
                                    Con más de 20 años de experiencia, en <strong>Royal Bus</strong> garantizamos un servicio seguro, cómodo y puntual.
                                </p>
                                <p className="text-white-75 font-weight-light mb-5">
                                    Nuestra flota propia cuenta con seguros y mantenimientos constantes.
                                </p>
                                <a className="btn btn-primary btn-xl js-scroll-trigger" href="#contact">
                                    Solicita una cotización
                                </a>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}

export default Masthead;
