import React, { Component } from 'react';
import '../contact.css';  // Asegúrate de ajustar la ruta si es necesario
import MailchimpForm from './MailchimpForm';

class Contact extends Component {
  render() {
    return (
      <section className="page-section" id="contact">
        <div className="container">
          {/* Título y descripción */}
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="mt-0">¡Contáctanos!</h2>
              <hr className="divider my-4" />
              <p className="text-muted mb-5">
                ¿Listo para cotizar con nosotros? Llámanos, mándanos un mensaje o completa el formulario y nos comunicaremos contigo lo antes posible.
              </p>
            </div>
          </div>

          {/* Contenedor de contacto y formulario */}
          <div className="contact-container">
            {/* Información de contacto */}
            <div className="contact-info">
              <div className="contact-item">
                <i className="fas fa-map-marker-alt fa-2x mb-3 text-muted"></i>
                <p>Dirección: Villa Nueva, Guatemala</p>
              </div>
              <div className="contact-item">
                <i className="fab fa-whatsapp fa-2x mb-3 text-muted" />
                <a className="d-block" href="tel:+50230355008">+502 3035-5008</a>
              </div>
              <div className="contact-item">
                <i className="fas fa-envelope fa-2x mb-3 text-muted" />
                <a className="d-block" href="mailto:ventas@royalbus.com.gt">ventas@royalbus.com.gt</a>
              </div>
            </div>

            {/* Formulario de contacto */}
            <div className="contact-form">
              <MailchimpForm />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
