import React, { Component } from 'react';

class Services extends Component {
    render() {
        return (
            <section className="page-section" id="services">
                <div className="container">
                    <h2 className="text-center mt-0">Nuestros Servicios</h2>
                    <hr className="divider my-4" />
                    <div className="row">
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="mt-5">
                                <i className="fas fa-4x fa-user-tie text-primary mb-4" />
                                <h3 className="h4 mb-2">Pilotos Profesionales</h3>
                                <p className="text-muted mb-0">
                                    Nuestro equipo de pilotos está altamente capacitado, brindando un servicio confiable, seguro y con trato cordial en todo momento.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="mt-5">
                                <i className="fas fa-4x fa-bus text-primary mb-4" />
                                <h3 className="h4 mb-2">Flota Moderna</h3>
                                <p className="text-muted mb-0">
                                    Unidades modernas, cómodas y adaptadas a diferentes necesidades, garantizando un viaje placentero para todos nuestros pasajeros.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="mt-5">
                                <i className="fas fa-4x fa-shield-alt text-primary mb-4" />
                                <h3 className="h4 mb-2">Seguridad Garantizada</h3>
                                <p className="text-muted mb-0">
                                    Todas nuestras unidades cuentan con seguros completos y tecnología avanzada, ofreciendo máxima protección durante el viaje.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="mt-5">
                                <i className="fas fa-4x fa-spray-can text-primary mb-4" />
                                <h3 className="h4 mb-2">Limpieza Impecable</h3>
                                <p className="text-muted mb-0">
                                    Realizamos limpieza profunda y mantenimientos regulares, asegurando espacios higiénicos y confortables para cada viaje.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Services;
