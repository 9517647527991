import React from 'react';
import '../mailchimpform.css'; // Asegúrate de que este archivo CSS esté correctamente vinculado

const MailchimpForm = () => {
  return (
    <div id="mc_embed_signup">
      <form
        action="https://royalbus.us8.list-manage.com/subscribe/post?u=48833e66a95f1a9559bb3cb72&amp;id=e011d8bd90&amp;f_id=00a70ce1f0"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
      >
        <div id="mc_embed_signup_scroll">

          {/* Campo de correo */}
          <div className="mc-field-group">
            <label htmlFor="mce-EMAIL">Dirección de correo electrónico <span className="asterisk">*</span></label>
            <input
              type="email"
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
              required
            />
          </div>

          {/* Campo de nombre */}
          <div className="mc-field-group">
            <label htmlFor="mce-FNAME">Nombre</label>
            <input
              type="text"
              name="FNAME"
              className="text"
              id="mce-FNAME"
            />
          </div>

          {/* Campo de teléfono */}
          <div className="mc-field-group">
            <label htmlFor="mce-PHONE">Teléfono</label>
            <input
              type="text"
              name="PHONE"
              className="text"
              id="mce-PHONE"
            />
          </div>

          {/* Campo de mensaje */}
          <div className="mc-field-group">
            <label htmlFor="mce-FMESSAGE">Mensaje</label>
            <input
              type="text"
              name="FMESSAGE"
              className="text"
              id="mce-FMESSAGE"
            />
          </div>

          {/* Respuestas de error/éxito */}
          <div id="mce-responses" className="clear">
            <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
            <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
          </div>

          {/* Prevención de bots */}
          <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
            <input
              type="text"
              name="b_48833e66a95f1a9559bb3cb72_e011d8bd90"
              tabIndex="-1"
              defaultValue=""
            />
          </div>

          {/* Botón de suscripción */}
          <div className="clear">
            <button
              type="submit"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button"
            >
              Enviar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MailchimpForm;
