import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

const PREFIX_URL = 'assets/img/portfolio/fullsize/';

class Portfolio extends Component {
    constructor(props) {
        super(props);
        this.images = this._getStaticImages().reverse(); // Invertir el orden de las imágenes
    }

    _getStaticImages() {
        return Array.from({ length: 17 }, (_, i) => ({
            original: `${PREFIX_URL}${i + 1}.webp`,
            thumbnail: `${PREFIX_URL}${i + 1}.webp`
        }));
    }

    render() {
        return (
            <div id="portfolio">
                <ImageGallery 
                    items={this.images}
                    autoPlay={true}
                    slideInterval={3000}
                />
            </div>
        );
    }
}

export default Portfolio;
